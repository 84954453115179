
import MeasurementSettings from "@/components/SelfContained/MeasurementSettings.vue";
import {bus} from "@/modules/eventBus";
import {defineComponent} from "vue";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  name: "UserSettingsMeasurements",
  components: {Button, MeasurementSettings},
  methods: {
    saveMeasurements(){
      bus.emit('measurements:save')
    },
    goBack(){
      this.$router.back()
    }
  }
})
